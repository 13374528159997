export const plansData = [
  {
    title: "Free",
    subtitle: "For Students and Single Users <br/> No credit card required",
    features: [
      {before: "1 Presentation ", button: "Portal", modal: "portalModal"},
      { text: "2 Presentations views" },
      {
        before: "3 ",
        button: "Show rooms",
        modal: "showroomsModal",
      },
      { before: "2 ", button: "Microsites", modal: "micrositesModal" },
      { text: "Design catalog of 10.000 images" },
      {  modules : true },
    ],
    regularPrice: "0",
    yearlyPrice: "0",
    button: "Try for free",
    toLink: "https://accounts.masterwizr.com/sign-up?license=free",
  },
  {
    title: "Personal",
    subtitle: "For Freelancers, Independents and Professional single users",
    features: [
      { before: "3 Presentation " , button: "Portals", modal: "portalModal" },
      { before: "Unlimited ", button: "Show Rooms", modal: "showroomsModal" },
      { before: "Unlimited ", button: "Cover Pages", modal: "coverpagesModal" },
      { before: "Unlimited ", button: "Microsites", modal: "micrositesModal" },
      { text: "Video meeting, 50 min" },
      {
        before: "Shareable ",
        button: "Personal Profile",
        modal: "personalprofileModal",
      },
      { modules2: true },
    ],
    regularPrice: "9",
    yearlyPrice: "99.99",
    license: "Free version, Plus:",
    extraClass: "recommended",
    button: "Buy now",
    toLink: "https://accounts.masterwizr.com/sign-up?license=personal",
  },
  {
    title: "Pro",
    subtitle: "Multiuser accounts for companies and organisations.",
    features: [
      { text: "5 seats included (1 user + 4 colleagues)" },
      { before: "Unlimited Presentation ", button: "Portals", modal: "portalModal" },
      { text: "Unlimited Presentations and video uploads" },
      { text: "Full functionality for multiuser storage, sharing, organizing" },
      { modules3: true },
      { text: "Design catalog of 75.000+ images" },
      { text: "All full versions" },
    ],
    regularPrice: "29",
    yearlyPrice: "299.99",
    license: "Personal version, Plus:",
    extraClass: "label",
    button: "Buy now",
    toLink: "https://accounts.masterwizr.com/sign-up?license=pro",
  },
];
