import React from "react";
import * as styles from "./Modal.module.scss";
import { IoIosClose } from "react-icons/io";
import { ImageOptimized } from "../ImageOptimized/ImageOptimized";
export default function Modal({ children, handleClose, title, image, description }) {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <h1 className={styles.title}>{title}</h1>
        <p>{description}</p>
        <ImageOptimized src={image} alt="banner" />
        {children}
        <button onClick={handleClose} className={styles.btn}>
          <IoIosClose />
        </button>
      </div>
    </div>
  );
}
