import React, { useState } from "react";
import PricingCards from "../../molecules/PricingCards/PricingCards";
import * as styles from "./PlansSection.module.scss";
import Arrow from "../../../images/right-arrow.png";

export default function PlansSection() {
  const [toggle, setToggle] = useState("Monthly");

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.title}>Plans</h1> */}
      <p className={styles.title}>Get WiZR!</p>
      <div className={styles.buttons}>
      <button
        onClick={() =>
          window.open(
            "https://masterwizr.com/plans",
            "_blank",
            "noopener,noreferrer"
          )
        }
        className={styles.plansBtn}
      >
        See All Plans <img src={Arrow} alt="Arrow Icon" />
      </button>
      <div className={styles.toggle}>
        <p
          onClick={() => setToggle("Monthly")}
          className={`${toggle === "Monthly" ? styles.active : ``}`}
        >
          Monthly
        </p>
        <p
          onClick={() => setToggle("Yearly")}
          className={`${toggle === "Yearly" ? styles.active : ``}`}
        >
          Yearly
        </p>
      </div>
    </div>
      <PricingCards toggle={toggle} />
    </div>
  );
}
