// extracted by mini-css-extract-plugin
export var buttonContainer = "PricingCards-module--buttonContainer--973fd";
export var card = "PricingCards-module--card--50127";
export var cardContainer = "PricingCards-module--cardContainer--8befb";
export var cards = "PricingCards-module--cards--ba126";
export var container = "PricingCards-module--container--81a47";
export var infoIcon = "PricingCards-module--infoIcon--2d4be";
export var license = "PricingCards-module--license--2a406";
export var price = "PricingCards-module--price--b0d67";
export var pricingBtn = "PricingCards-module--pricingBtn--456a6";
export var saveText = "PricingCards-module--saveText--2ac30";
export var subtitle = "PricingCards-module--subtitle--9b038";
export var title = "PricingCards-module--title--01664";
export var top = "PricingCards-module--top--71f33";
export var trialText = "PricingCards-module--trialText--a52bd";